<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid" v-if="!addStatus && !editStatus">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5> RCS Template Settings</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="templateSettingList" v-model:selection="selectedProduct" :lazy="true"
                                :paginator="true" :rows="30" :totalRecords="totalRecords" :loading="loading"
                                @page="onPage($event)" class="p-datatable-users" data-key="tf1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <Toolbar>
                                    <template v-slot:left>
                                        <h5 class="p-m-0">RCS Template Settings</h5>
                                    </template>
                                    <template v-slot:right>
                                        <Button label="Copy to other client  " icon="pi pi-copy" class="p-mr-2"
                                            @click="onSelectRCSSettings" style="margin: right 3.5rem;" />

                                        <Button label="Add RCS Template" icon="pi pi-plus"
                                            @click="onAddRCSTemplateSetting" style="margin: right 3.5rem;" />

                                        <!-- <Toast />
                                        <FileUpload mode="basic" name="demo[]" url="./upload.php" accept="*" :maxFileSize="1000000" @upload="onUpload" chooseLabel="Add Client wTemplate"/> -->
                                    </template>
                                </Toolbar>
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>

                                <Column selectionMode="multiple" headerStyle="width: 5%"></Column>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column field="name" header="Template Name" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text"> {{ data.tf3 }}</div>
                                    </template>
                                </Column>
                                <Column field="body" header="Template For" headerStyle="width: 24%">
                                    <template #body="{ data }">
                                        <!-- <div class="p-text-capitalize"> {{ data.tf34 }}</div> -->
                                        <div class="p-text-capitalize">
                                            <div class="p-text"> {{ data.tf22 }}</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Langauge" headerStyle="width: 19%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">
                                            <div v-if="data.tf6 == 1"> English</div>
                                            <div v-else-if="data.tf6 == 2"> Marathi </div>
                                            <div v-else-if="data.tf6 == 3"> Kannad</div>
                                            <div v-else-if="data.tf6 == 4"> Tamil</div>
                                        </div>
                                    </template>
                                </Column>
                                <Column header="Vendor Id" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <div class="p-text-capitalize">{{ data.tf20 }}</div>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 10%">
                                    <template #body="{ data }">
                                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                            @click="onEditRCSTemplateSettings(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit RCS Dialog starts here -->
    <AddEditRCSTemplateSettings :propsData=editableData :propsData1=shorttemplateNameList :propsData2="chatbotList"
        :propsData3="languageList" @go_to_previous="handleButtonClick" v-if="addStatus || editStatus" />
    <!-- Add/Edit RCS Dialog ends here -->
    <!-- Copy Settings dialog start here -->
    <Dialog v-model:visible="isCopyRCSSetting" :style="{ width: '600px' }" header="Copy RCS Settings" :modal="true"
        class="p-fluid">
        <div class="p-field p-col-12 p-md-9">
            <label for="client_name">
                Select Client
            </label>
            <Dropdown v-model="client_name" :options="clientList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select client" />
        </div>
        <div class="p-field p-col-12 p-md-9" v-if="selectedProduct == '' || selectedProduct == null">
            <label for="chatbot">
                Select chatbot
            </label>
            <Dropdown v-model="chatbot" :options="chatbotList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select chatbot" />

        </div>
        <template #footer>
            <Button v-if="!copysettingloader" label="Submit" icon="pi pi-check" class="p-button-text"
                :disabled="client_name == null || client_name == ''" @click="onCopySettingSubmit" style="width: 90px" />
            <Button v-show="copysettingloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Copy Settings dialog end here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import AddEditRCSTemplateSettings from './AddEditRCSTemplateSettings.vue';

export default {
    components: {
        AddEditRCSTemplateSettings
    },
    data() {
        return {
            templateSettingList: [],
            templateEventList: [],
            v$: useValidate(),
            isDialogOpen: false,
            // isSubmitted: false,
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            isEditwTemplate: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            // wTemplateError: "",
            dialogHeader: "",
            validation: {},
            // wTemplateId: '',
            profileImageError: '',

            languageList: [],
            shorttemplateNameList: [],
            templatereplytypeList: [
                { label: 'Stop Chatbot', value: 0 },
                { label: 'Text', value: 1 },
                { label: 'Button', value: 2 },
                { label: 'Number Type', value: 3 },
                { label: 'aadhar number', value: 4 },
                { label: 'OTP', value: 5 },
                { label: 'Voter Card Number', value: 6 },
            ],

            chatbotList: [],
            chatbot: '',
            submitted: false,
            templateName: '',
            templateAction: '',
            templateEvent: '',
            templateBody: '',
            template_variables: '',
            language: '',
            template_reply_type: '',
            next_template_name: '',

            first_button_name: '',
            first_button_template_name: '',
            second_button_name: '',
            second_button_template_name: '',
            third_button_name: '',
            third_button_template_name: '',
            fourth_button_name: '',
            fourth_button_template_name: '',

            vendors_template_id: '',
            header_file_type: '',
            headerFileTypeList: [
                { label: 'Image', value: 1 },
                { label: 'Document', value: 2 },
                { label: 'Video', value: 3 },
                { label: 'Audio', value: 4 },
            ],
            shorttemplateName: '',
            headerstatus: 0,
            header_file_url: null,
            header_file_url_edit: null,
            chatbotId: '',
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',
            file: '',
            row_id: 0,
            foundUniqueIdErrorMsg: '',

            isCopyRCSSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedRCSSettings: [],
            client_name: '',
            localClientName: '',
            addStatus: false,
            editStatus: false,
            editableData: null,
        };
    },
    validations() {
        return {
            chatbotId: { required: helpers.withMessage('Please select chatbotId', required) },
            language: { required: helpers.withMessage('Please select language', required) },
            templateName: { required: helpers.withMessage('Please enter template name', required) },
            shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
            templateBody: { required: helpers.withMessage('Please enter template body', required) },
            // template_reply_type: { required: helpers.withMessage('Please enter template reply type', required) },
            // header_file_type: { required: helpers.withMessage('please select Header File type', required) },
            // template_variables: { required: helpers.withMessage('Please enter template variables', required) },
            // next_template_name: { required: helpers.withMessage('Please enter next template name', required) },
            vendors_template_id: { required: helpers.withMessage('Please enter vendor template Id', required) },
            headerstatus: { required: helpers.withMessage('Please enter header status', required) },
            // header_file_url: {
            //     requiredIf: helpers.withMessage(
            //         "Please select header file",
            //         requiredIf(
            //             this.headerstatus == 1
            //         )
            //     ),
            // },
            first_button_template_name: {
                requiredIf: helpers.withMessage("Please select first button template",
                    requiredIf(
                        this.first_button_name != ''
                    )
                ),
            },
            second_button_template_name: {
                requiredIf: helpers.withMessage("Please select second button template",
                    requiredIf(
                        this.second_button_name != ''
                    )
                ),
            },
            third_button_template_name: {
                requiredIf: helpers.withMessage("Please select third button template",
                    requiredIf(
                        this.third_button_name != ''
                    )
                ),
            },
            fourth_button_template_name: {
                requiredIf: helpers.withMessage("Please select fourth button template",
                    requiredIf(
                        this.fourth_button_name != ''
                    )
                ),
            },

        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getLanguages();
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.loading = true;
        this.getRCSTemplateSettings({ clientId: this.routeParam });
        this.getTemplateDescriptions();
        this.getchatbotList();
    },
    methods: {
        handleButtonClick() {
            this.addStatus = false;
            this.editStatus = false;
            this.editableData = null;
            this.getRCSTemplateSettings({ clientId: this.routeParam })
        },

        getLanguages() {
            this.ApiService.getLanguages().then((data) => {
                if (data.status == 200) {
                    this.languageList = data.data;
                } else {
                    this.languageList = '';
                }
            });
        },

        getAllRCSTemplateOnly() {
            this.loading = true;
            this.ApiService.getAllRCSTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        clearuploadimage() {
            this.profileImageError = '';
            this.header_file_url = null;
            this.profileUrl = '';
            this.header_file_url_edit = null;
        },

        isAlreadyPresentRCSTemplateName(event) {
            if (event.target.value.length > 4) {
                this.ApiService.isAlreadyPresentRCSTemplateName({ uniqueName: event.target.value, rowId: this.row_id, clientId: this.routeParam }).then((data) => {
                    if (data.status == 200) {
                        this.foundUniqueIdErrorMsg = '';
                        // this.foundUniqueId = data.data;
                        if (data.count > 0)
                            this.foundUniqueIdErrorMsg = "This template name already exist";
                        this.loading = false;
                    } else {
                        this.foundUniqueIdErrorMsg = '';
                        this.loading = false;
                    }
                    this.loading = false;
                });
            }
        },

        onSelectRCSSettings() {
            // this.copysettingloader= true;
            this.ApiService.getClientListOnlyExceptGivenId({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });

            if (this.selectedProduct == '' || this.selectedProduct == null) {
                this.getchatbotList();
                // this.ApiService.getchatbotList().then((data) => {
                //     if (data.status == 200) {
                //         this.chatbotList = data.data;
                //     } else {
                //         this.chatbotList = null;
                //     }
                // });
            }
            this.isCopyRCSSetting = true;


        },

        getchatbotList() {
            this.ApiService.getchatbotList().then((data) => {
                if (data.status == 200) {
                    this.chatbotList = data.data;
                } else {
                    this.chatbotList = null;
                }
            });
        },

        onCopySettingSubmit() {
            this.copysettingloader = true;
            var formData = new FormData();
            if (this.selectedProduct) {
                let vals = this.selectedProduct.map(function (item) {
                    return item;
                });
                this.selectedRCSSettings = vals;
                formData.append("selectedRCSSetting", JSON.stringify(this.selectedRCSSettings));
            }

            formData.append("clientId", this.routeParam);
            formData.append("CopyIntoClientId", this.client_name.value);
            // if (this.selectedRCSSettings)

            if (this.chatbot)
                formData.append("chatbotId", this.chatbot.value);
            this.ApiService.copyRCSSettingToOtherClient(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getRCSTemplateSettings({ clientId: this.routeParam });
                    this.copysettingloader = false;
                    this.isCopyRCSSetting = false;
                    this.client_name = "";
                    this.chatbot = "";
                    this.selectedProduct = null;
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });


        },

        getTemplateDescriptions() {
            this.loading = true;
            this.ApiService.getTemplateDescriptions().then((data) => {
                if (data.status == 200) {
                    this.shorttemplateNameList = data.data;
                } else {
                    this.shorttemplateNameList = '';
                }
                this.loading = false;
            });
        },

        async onSelectProfile(e) {
            if (this.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }

            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.header_file_url = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.header_file_url = '';
            } else {
                this.profileImageError = '';
                if (this.header_file_type.value == 1) {
                    this.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                }
                // this.header_file_url = this.$refs.docuploadref.files[0];
                // this.header_file_url = this.$refs.tuneuploadeditref.files[0];
                // this.header_file_url = e.files[0];
            }
        },

        onAddRCSTemplateSetting() {
            this.addStatus = true;
            this.editStatus = false;
        },

        onEditRCSTemplateSettings(e) {
            this.addStatus = false;
            this.editStatus = true;
            this.editableData = e;
        },

        async getRCSTemplateSettings(params) {
            try {
                const result = await this.ApiService.getRCSTemplateSettings({ ...params })
                this.loading = false;
                if (!result.success) {
                    return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });
                }
                this.templateSettingList = result.data;
                this.totalRecords = result.count;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.', life: 3000 });

            }
        },

        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "whatsapp-template-settings",
                params: { clientSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "web-bot-template-settings",
                params: { clientSubId: this.routeParam },

            });
        }
    }

};
</script>

<style scoped></style>
