<template>
    <Toast :baseZIndex="9999" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>{{ this.dialogHeader }}</h5>
                <div>
                    <Button label="Back" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                </div>
            </div>
            <div>
                <div class="p-pt-2 p-px-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4">
                            <label for="chatbotId">
                                ChatbotId
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <Dropdown id="chatbotId" v-model="chatbotId" :options="chatbotList" optionLabel="label"
                                placeholder="Select ..." appendTo="body"></Dropdown>

                            <!-- <InputText id="chatbotId" class="p-text-capitalize" v-model.trim="chatbotId" required="true"
                            maxlength="20" autofocus :class="{ 'p-invalid': submitted && !chatbotId }" /> -->
                            <small class="p-invalid p-error" v-if="v$.chatbotId.$error">{{
        v$.chatbotId.$errors[0].$message
    }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="language">
                                Language
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <Dropdown id="language" v-model="language" :options="languageList" optionLabel="label"
                                placeholder="Select ..." appendTo="body"></Dropdown>
                            <small class="p-invalid p-error" v-if="v$.language.$error">{{
        v$.language.$errors[0].$message
    }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="templateName">
                                Template Name
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="templateName" v-model.trim="templateName"
                                @input="isAlreadyPresentRCSTemplateName($event)" required="true" maxlength="20"
                                autofocus :class="{ 'p-invalid': submitted && !templateName }" />
                            <small class="p-invalid p-error" v-if="v$.templateName.$error">{{
        v$.templateName.$errors[0].$message
    }}</small>
                            <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg
                                }}</small>

                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="shorttemplateName">
                                Template For
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <Dropdown id="shorttemplateName" v-model="shorttemplateName"
                                :options="shorttemplateNameList" optionLabel="label" placeholder="Select ..."
                                appendTo="body"></Dropdown>

                            <!-- <InputText id="shorttemplateName" class="p-text-capitalize" v-model.trim="shorttemplateName"
                            required="true" maxlength="100" /> -->
                            <small class="p-invalid p-error" v-if="v$.shorttemplateName.$error">{{
        v$.shorttemplateName.$errors[0].$message
    }}</small>
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label for="vendors_template_id">
                                Vendor Template Id
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="vendors_template_id" v-model.trim="vendors_template_id" maxlength="20" />
                            <small class="p-invalid p-error" v-if="v$.vendors_template_id.$error">{{
        v$.vendors_template_id.$errors[0].$message
    }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="templateBody">
                                Template Body
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <Textarea id="templateBody" v-model="templateBody" :autoResize="true" rows="2" cols="30"
                                placeholder="enter template body" autofocus
                                :class="{ 'p-invalid': submitted && !templateBody }" />
                            <!-- <InputText id="templateBody" class="p-text-capitalize" v-model.trim="templateBody" required="true"
                            maxlength="20" autofocus :class="{ 'p-invalid': submitted && !templateBody }" /> -->
                            <small class="p-invalid p-error" v-if="v$.templateBody.$error">{{
        v$.templateBody.$errors[0].$message
    }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="template_variables">
                                Template Variables
                                <!-- <span class="p-invalid p-error">*</span> -->
                            </label>
                            <Textarea id="template_variables" :autoResize="true" rows="2"
                                cols="30" v-model.trim="template_variables" required="true"
                                placeholder="enter template variables" autofocus />
                            <!-- <small class="p-invalid p-error" v-if="v$.template_variables.$error">{{
                            v$.template_variables.$errors[0].$message
                        }}</small> -->
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="template_reply_type">
                                Reply Type
                                <!-- <span class="p-invalid p-error">*</span> -->
                            </label>
                            <Dropdown id="template_reply_type" v-model="template_reply_type"
                                :options="templatereplytypeList" optionLabel="label" placeholder="Select ..."
                                appendTo="body">
                            </Dropdown>
                            <!-- <small class="p-invalid p-error" v-if="v$.template_reply_type.$error">{{
                            v$.template_reply_type.$errors[0].$message
                        }}</small> -->
                        </div>
                        <div class="p-field p-col-12 p-md-8">
                            <label for="next_template_name">
                                Next Template Name
                                <!-- <span class="p-i   nvalid p-error">*</span> -->
                            </label>
                            <Dropdown id="next_template_name" showClear filter v-model="next_template_name"
                                :options="templateEventList" optionLabel="label" placeholder="Select ..."
                                appendTo="body">
                            </Dropdown>
                            <!-- <small class="p-invalid p-error" v-if="v$.next_template_name.$error">{{
                            v$.next_template_name.$errors[0].$message
                        }}</small> -->
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label>Header status <span class="p-invalid p-error"> *</span></label>
                            <div class="p-field-radiobutton">
                                <RadioButton id="headerstatusavl" name="option" v-bind:value="1" v-model="headerstatus"
                                    :checked="headerstatus == 1" />
                                <label for="headerstatusavl" class="p-mr-2">Available</label>
                                <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0"
                                    v-model="headerstatus" :checked="headerstatus == 0" />
                                <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1">
                            <label for="header_file_type">
                                header_file_type
                                <!-- <span class="p-invalid p-error">*</span> -->
                            </label>
                            <Dropdown id="header_file_type" showClear v-model="header_file_type"
                                :options="headerFileTypeList" optionLabel="label" placeholder="Select ..."
                                @change="clearuploadimage()" appendTo="body">
                            </Dropdown>
                            <!-- <small class="p-invalid p-error" v-if="v$.header_file_type.$error">{{
                            v$.header_file_type.$errors[0].$message
                        }}</small> -->
                        </div>

                        <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1 && header_file_type">
                            <label>Upload Header <span class="p-invalid p-error">*</span></label>
                            <div v-if="header_file_type.value == 1">
                                <FileUpload v-model="header_file_url" mode="basic" ref="header_file_url"
                                    :maxFileSize="1000000" accept="image/*" chooseLabel="Upload Header Image" auto
                                    @select="onSelectProfile">
                                </FileUpload>
                                <span v-if="header_file_url_edit && profileUrl == null">
                                    <img class="p-pt-2" role="presentation" alt="edit" :src="header_file_url_edit"
                                        width="120" height="120" />
                                </span>
                            </div>
                            <div v-if="header_file_type.value == 2">
                                <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima"
                                    accept="pdf/*" v-model="header_file_url" chooseLabel="Upload Header Document"
                                    v-on:change="onSelectProfile()" />
                            </div>
                            <div v-if="header_file_type.value == 3">
                                <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima"
                                    accept="mp4/*" v-model="header_file_url" chooseLabel="Upload Header Video"
                                    v-on:change="onSelectProfile()" />
                            </div>
                            <div v-if="header_file_type.value == 4">
                                <FileUpload v-model="header_file_url" mode="basic" ref="tuneuploadeditref"
                                    :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                                    @select="onSelectProfile" />
                            </div>
                            <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError }}</small>
                            <div>
                                <div v-if="header_file_type.value == 1">
                                    <img v-if="profileUrl" class="p-pt-2" role="presentation" :alt="profileUrl.name"
                                        :src="profileUrl.url" width="120" height="120" />
                                </div>
                                <div v-if="header_file_type.value != 1">
                                    <div class="p-field p-col-12 p-md-12" v-if="profileUrl != null">{{
        profileUrl.name
    }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label for="first_button_name">
                                First Button Name
                            </label>
                            <InputText id="first_button_name" v-model.trim="first_button_name"
                                required="true" maxlength="25" />
                        </div>
                        <div class="p-field p-col-12 p-md-8" v-if="first_button_name">
                            <label for="first_button_template_name">
                                First Button Template Name

                                <span class="p-invalid p-error" v-if="first_button_name != ''">*</span>
                            </label>
                            <Dropdown id="first_button_template_name" showClear filter
                                v-model="first_button_template_name" :options="templateEventList" optionLabel="label"
                                placeholder="Select ..." appendTo="body">
                            </Dropdown>
                            <div v-if="first_button_name != ''">
                                <small class="p-invalid p-error" v-if="v$.first_button_template_name.$error">{{
        v$.first_button_template_name.$errors[0].$message
    }}</small>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="second_button_name">
                                Second Button Name
                            </label>
                            <InputText id="second_button_name"
                                v-model.trim="second_button_name" maxlength="25" />
                        </div>

                        <div class="p-field p-col-12 p-md-8" v-if="second_button_name">
                            <label for="second_button_template_name">
                                Second Button Template Name
                                <span class="p-invalid p-error" v-if="second_button_name != ''">*</span>

                            </label>
                            <Dropdown id="second_button_template_name" showClear filter
                                v-model="second_button_template_name" :options="templateEventList" optionLabel="label"
                                placeholder="Select ..." appendTo="body">
                            </Dropdown>
                            <div v-if="second_button_name != ''">
                                <small class="p-invalid p-error" v-if="v$.second_button_template_name.$error">{{
        v$.second_button_template_name.$errors[0].$message
    }}</small>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label for="third_button_name">
                                Third Button Name
                            </label>
                            <InputText id="third_button_name" v-model.trim="third_button_name"
                                maxlength="25" />
                        </div>
                        <div class="p-field p-col-12 p-md-8" v-if="third_button_name">
                            <label for="third_button_template_name">
                                Third Button Template Name
                                <span class="p-invalid p-error" v-if="third_button_name != ''">*</span>
                            </label>
                            <Dropdown id="third_button_template_name" showClear filter
                                v-model="third_button_template_name" :options="templateEventList" optionLabel="label"
                                placeholder="Select ..." appendTo="body">
                            </Dropdown>
                            <div v-if="third_button_name != ''">
                                <small class="p-invalid p-error" v-if="v$.third_button_template_name.$error">{{
        v$.third_button_template_name.$errors[0].$message
                                    }}</small>
                            </div>
                        </div>

                        <div class="p-field p-col-12 p-md-4">
                            <label for="fourth_button_name">
                                Fourth Button Name
                            </label>
                            <InputText id="fourth_button_name" class="p-text-capitalize"
                                v-model.trim="fourth_button_name" required="true" maxlength="20" />
                        </div>
                        <div class="p-field p-col-12 p-md-8" v-if="fourth_button_name">
                            <label for="fourth_button_template_name">
                                Fourth Button Template Name
                                <span class="p-invalid p-error" v-if="fourth_button_name != ''">*</span>
                            </label>
                            <Dropdown id="fourth_button_template_name" showClear filter
                                v-model="fourth_button_template_name" :options="templateEventList" optionLabel="label"
                                placeholder="Select ..." appendTo="body">
                            </Dropdown>
                            <div v-if="fourth_button_name != ''">
                                <small class="p-invalid p-error" v-if="v$.fourth_button_template_name.$error">{{
                                    v$.fourth_button_template_name.$errors[0].$message
                                    }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
            class="p-button-text p-ml-auto" @click="addNewRCSTemplate" style="width: 90px;" :disabled="showLoader"></Button>
        <!-- <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button> -->
    </div>

</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { required, helpers, requiredIf } from '@vuelidate/validators';

export default {
    props: {
        propsData: {
            required: false,
        },
        propsData1: {
            required: true,
        },
        propsData2: {
            required: true,
        },
        propsData3: {
            required: true,
        },
    },
    emits: {
        go_to_previous: null,
    },
    data() {
        return {
            templateSettingList: [],
            templateEventList: [],
            v$: useValidate(),
            isDialogOpen: false,
            showLoader: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            isEditwTemplate: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            dialogHeader: "",
            validation: {},
            profileImageError: '',

            languageList: [],
            shorttemplateNameList: [],
            templatereplytypeList: [
                { label: 'Stop Chatbot', value: 0 },
                { label: 'Text', value: 1 },
                { label: 'Button', value: 2 },
                { label: 'Number Type', value: 3 },
                { label: 'aadhar number', value: 4 },
                { label: 'OTP', value: 5 },
                { label: 'Voter Card Number', value: 6 },
            ],

            chatbotList: [],
            chatbot: '',
            submitted: false,
            templateName: '',
            templateAction: '',
            templateEvent: '',
            templateBody: '',
            template_variables: '',
            language: '',
            template_reply_type: '',
            next_template_name: '',

            first_button_name: '',
            first_button_template_name: '',
            second_button_name: '',
            second_button_template_name: '',
            third_button_name: '',
            third_button_template_name: '',
            fourth_button_name: '',
            fourth_button_template_name: '',

            vendors_template_id: '',
            header_file_type: '',
            headerFileTypeList: [
                { label: 'Image', value: 1 },
                { label: 'Document', value: 2 },
                { label: 'Video', value: 3 },
                { label: 'Audio', value: 4 },
            ],
            shorttemplateName: '',
            headerstatus: 0,
            header_file_url: null,
            header_file_url_edit: null,
            chatbotId: '',
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',
            file: '',
            row_id: 0,
            foundUniqueIdErrorMsg: '',

            isCopyRCSSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedRCSSettings: [],
            client_name: '',
            localClientName: '',
        };
    },
    validations() {
        return {
            chatbotId: { required: helpers.withMessage('Please select chatbotId', required) },
            language: { required: helpers.withMessage('Please select language', required) },
            templateName: { required: helpers.withMessage('Please enter template name', required) },
            shorttemplateName: { required: helpers.withMessage('Please enter short template name', required) },
            templateBody: { required: helpers.withMessage('Please enter template body', required) },
            vendors_template_id: { required: helpers.withMessage('Please enter vendor template Id', required) },
            headerstatus: { required: helpers.withMessage('Please enter header status', required) },
            first_button_template_name: {
                requiredIf: helpers.withMessage("Please select first button template",
                    requiredIf(
                        this.first_button_name != ''
                    )
                ),
            },
            second_button_template_name: {
                requiredIf: helpers.withMessage("Please select second button template",
                    requiredIf(
                        this.second_button_name != ''
                    )
                ),
            },
            third_button_template_name: {
                requiredIf: helpers.withMessage("Please select third button template",
                    requiredIf(
                        this.third_button_name != ''
                    )
                ),
            },
            fourth_button_template_name: {
                requiredIf: helpers.withMessage("Please select fourth button template",
                    requiredIf(
                        this.fourth_button_name != ''
                    )
                ),
            },

        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.shorttemplateNameList = this.propsData1;
        this.chatbotList = this.propsData2;
        this.languageList = this.propsData3;
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.loading = true;
        if (this.propsData != null) {
            this.onEditRCSTemplateSettings(this.propsData);
        } else {
            this.getAllRCSTemplateOnly();
            this.onAddRCSTemplateSetting();
        }
    },
    methods: {
        goToPrevious() {
            this.$emit('go_to_previous');
        },

        getAllRCSTemplateOnly() {
            this.loading = true;
            this.ApiService.getAllRCSTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                } else {
                    this.templateEventList = '';
                }
                this.loading = false;
            });
        },

        clearuploadimage() {
            this.profileImageError = '';
            this.header_file_url = null;
            this.profileUrl = '';
            this.header_file_url_edit = null;
        },

        isAlreadyPresentRCSTemplateName(event) {
            if (event.target.value.length > 4) {
                this.ApiService.isAlreadyPresentRCSTemplateName({ uniqueName: event.target.value, rowId: this.row_id, clientId: this.routeParam }).then((data) => {
                    if (data.status == 200) {
                        this.foundUniqueIdErrorMsg = '';
                        // this.foundUniqueId = data.data;
                        if (data.count > 0)
                            this.foundUniqueIdErrorMsg = "This template name already exist";
                        this.loading = false;
                    } else {
                        this.foundUniqueIdErrorMsg = '';
                        this.loading = false;
                    }
                    this.loading = false;
                });
            }
        },

        async onSelectProfile(e) {
            if (this.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }
            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.header_file_url = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.header_file_url = '';
            } else {
                this.profileImageError = '';
                if (this.header_file_type.value == 1) {
                    this.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                }
            }
        },

        onAddRCSTemplateSetting() {
            this.dialogHeader = 'Add RCS Template';
            this.row_id = 0;
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.fourth_button_name = '';
            this.fourth_button_template_name = '';

            this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
        },

        onEditRCSTemplateSettings(e) {
            this.templateName = '';
            this.templateAction = '';
            this.templateEvent = '';
            this.templateBody = '';
            this.template_variables = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.first_button_name = '';
            this.first_button_template_name = '';
            this.second_button_name = '';
            this.second_button_template_name = '';
            this.third_button_name = '';
            this.third_button_template_name = '';
            this.fourth_button_name = '';
            this.fourth_button_template_name = '';
            this.vendors_template_id = '';
            this.header_file_type = '';
            this.shorttemplateName = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.chatbotId = '';
            this.profileUrl = null;
            this.submitted = false;
            this.ApiService.getAllRCSTemplateOnly({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                    let templateEventnextbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf8;
                    });
                    if (templateEventnextbtn.length > 0) {
                        this.next_template_name = templateEventnextbtn[0];
                    }

                    let templateEventfirstbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf10;
                    });
                    if (templateEventfirstbtn.length > 0) {
                        this.first_button_template_name = templateEventfirstbtn[0];
                    }

                    let templateEventSecondbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf12;
                    });
                    if (templateEventSecondbtn.length > 0) {
                        this.second_button_template_name = templateEventSecondbtn[0];
                    }

                    let templateEventthirdbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf14;
                    });
                    if (templateEventthirdbtn.length > 0) {
                        this.third_button_template_name = templateEventthirdbtn[0];
                    }
                    let templateEventfourthbtn = this.templateEventList.filter(function (item) {
                        return item.value == e.tf16;
                    });
                    if (templateEventfourthbtn.length > 0) {
                        this.fourth_button_template_name = templateEventfourthbtn[0];
                    }
                } else {
                    this.templateEventList = '';
                }
            });
            this.row_id = e.tf1;
            if (e.tf2 !== 0) {
                let chatbotListId = this.chatbotList.filter(function (item) {
                    return item.value == e.tf2;
                });
                if (chatbotListId.length > 0) {
                    this.chatbotId = chatbotListId[0];
                }
            }
            this.templateName = e.tf3;
            this.templateBody = e.tf4;
            this.template_variables = e.tf5;
            let languageListId = this.languageList.filter(function (item) {
                return item.value == e.tf6;
            });
            if (languageListId.length > 0) {
                this.language = languageListId[0];
            }
            this.shorttemplateName = '';
            if (e.tf23 !== 0) {
                let shorttemplateNameListId = this.shorttemplateNameList.filter(function (item) {
                    return item.value == e.tf23;
                });
                if (shorttemplateNameListId.length > 0) {
                    this.shorttemplateName = shorttemplateNameListId[0];
                }
            }
            let template_reply_type_id = this.templatereplytypeList.filter(function (item) {
                return item.value == e.tf7;
            });
            if (template_reply_type_id.length > 0) {
                this.template_reply_type = template_reply_type_id[0];
            }

            let header_file_type_id = this.headerFileTypeList.filter(function (item) {
                return item.value == e.tf21;
            });
            if (header_file_type_id.length > 0) {
                this.header_file_type = header_file_type_id[0];
            }
            this.next_template_name = e.tf8;
            this.first_button_name = e.tf9;
            this.second_button_name = e.tf11;
            this.third_button_name = e.tf13;
            this.fourth_button_name = e.tf15;
            this.vendors_template_id = e.tf20;
            this.headerstatus = e.tf17;
            if (e.tf18 != null || e.tf18 != '') {
                this.header_file_url_edit = e.tf18;
            }
            this.dialogHeader = "Edit RCS Template";
            this.isDialogOpen = true;
            this.submitted = false;
        },

        async addNewRCSTemplate() {
            this.submitted = true;
            this.v$.$validate();
            let formData = new FormData();
            formData.append("tf1", this.row_id);
            formData.append("tf2", this.chatbotId.value);
            formData.append('tf3', this.templateName);
            formData.append('tf4', this.templateBody);
            formData.append('tf5', this.template_variables);
            formData.append('tf6', this.language.value);
            if (this.template_reply_type)
                formData.append('tf7', this.template_reply_type.value);
            if (this.next_template_name) {
                formData.append('tf8', this.next_template_name.value);
            } else {
                formData.append('tf8', '');
            }
            formData.append('tf9', this.first_button_name);
            if (this.first_button_template_name !== '' && this.first_button_template_name !== null) {
                formData.append('tf10', this.first_button_template_name.value);
            } else {
                formData.append('tf10', '');
            }
            formData.append('tf11', this.second_button_name);
            if (this.second_button_template_name !== '' && this.second_button_template_name !== null) {
                formData.append('tf12', this.second_button_template_name.value);
            } else {
                formData.append('tf12', '');
            }
            formData.append('tf13', this.third_button_name);
            if (this.third_button_template_name !== '' && this.third_button_template_name !== null) {
                formData.append('tf14', this.third_button_template_name.value);
            }
            else {
                formData.append('tf14', '');
            }
            formData.append('tf15', this.fourth_button_name);
            if (this.fourth_button_template_name !== '' && this.fourth_button_template_name !== null) {
                formData.append('tf16', this.fourth_button_template_name.value);
            }
            else {
                formData.append('tf16', '');
            }
            formData.append('tf17', this.headerstatus);
            if (this.headerstatus == 1) {
                if (this.header_file_url != null) {
                    formData.append('tf18', this.header_file_url);
                    formData.append('tf21', this.header_file_type.value);
                }
            } else {
                formData.append('tf18', '');
                formData.append('tf21', 0);
            }

            formData.append('tf20', this.vendors_template_id);
            formData.append('tf22', this.shorttemplateName.label);
            formData.append('tf23', this.shorttemplateName.value);
            formData.append('clientId', this.routeParam);

            if (!this.v$.$error && this.foundUniqueIdErrorMsg == '') {
                this.showLoader = true;
                let result = await this.ApiService.addUpdateClientRCSTemplates(formData)
                this.showLoader = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                    this.wTemplateId = '';
                    this.isDialogOpen = false;
                    setTimeout(() => {
                        this.v$.$reset();
                    }, 0);
                } else {
                    this.isDialogOpen = false;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'RCS Template not added. Please try again', life: 3000 });

                }
            }
        },
    }
};
</script>

<style scoped></style>
